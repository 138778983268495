import AgePicker from '../age-picker'
import Validation from '../validation'
import {initTooltip} from '../helper'
import DataLayerHelper from '../data_layer_helper'

var PassengersFrom = function($form, store){
  this.form   = $form;

  this.store  = store;
  this.store.subscribers.push(this);
  this.onStoreChange = this.onStoreChange.bind(this);

  this.submitButtonBlock = $('.js-add-to-basket-block');
  this.submitButton = this.submitButtonBlock.find('.js-add-to-basket-button');
  this.totalPrice   = this.submitButtonBlock.find('.js-total-cost-price');
  this.dataLayer    = new DataLayerHelper

  this.cancelLinks  = this.form.find('.js-cancel-passenger');
  this.services     = this.form.find('.js-service-data');
  this.initializeEvents();
};

PassengersFrom.prototype.getGTMEcommerceForDataLayer = function(){
  return this.form.find('.js-data-layer').toArray().map((doc,i)=>{
    let dataLayerData = $(doc)
    return {
      item_id: dataLayerData.data('train'),
      price: dataLayerData.data('comission'),
      wagon_type: dataLayerData.data('wagontypeid')
    }
  })
};


PassengersFrom.prototype.initializeEvents = function(){
  var self = this;
  self.form.find('.age-picker').addClass('hidden').hide();
  
  $('.js-document-age').datepicker({
    changeMonth: true,
    changeYear: true,
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    yearRange: '-15:+0',
    maxDate: '0',
  });

  this.submitButton.on('click', function(event){
    let formValid = self.valid()
    if(formValid){
      var form = self.form.find('.js-create-basket-form');
      form.find('[name=roundtrip]').val(scenario.isRoundtrip());
      render.basket(form);
    }

    self.dataLayer.push({ ecommerce: null })
    self.dataLayer.push({
      event: "Add to basket",
      formValid: formValid,
      ecommerce: self.getGTMEcommerceForDataLayer()
    })
  });

  // this.submitButton.on('click', function(event){
  //   let formValid = self.valid()
  //   if(formValid){
  //     var form = self.form.find('.js-create-basket-form');
  //     form.find('[name=roundtrip]').val(scenario.isRoundtrip());
  //     grecaptcha.execute(reCAPTCHA_site_key, { action: 'create_booking' }).then(function (token) {
  //       // Генерируется во вьюхе с помощью recaptcha_v3
  //       // надо получать свежий токен перед отправкой формы, так
  //       // как старый мог протухнуть
  //       setInputWithRecaptchaResponseTokenForCreateBooking('g-recaptcha-response-data-create-booking', token);
  //       render.basket(form);
  //     }, function (reason) {
  //       console.log(reason);
  //     });
  //   } 
      
  //   self.dataLayer.push({ ecommerce: null })
  //   self.dataLayer.push({
  //     event: "Add to basket",
  //     formValid: formValid,
  //     ecommerce: self.getGTMEcommerceForDataLayer()
  //   })
  // });

  this.cancelLinks.on('click', function(event){
    new ConfirmPopup(I18n.t('decline_popup.cancel_document'),function(answer){
      if(answer === 'yes'){
        var passenger = $(event.target).parents('.js-user-data');
        if(scenario.isTransfer()){
           $.each( passenger.find('.train-info'), function(index, trainInfo){
            self.store.deletePlace({
              place: $(trainInfo).find('[name*=place_num]').val(),
              wagon: $(trainInfo).find('[name*=wagon]').val(),
              train: $(trainInfo).find('[name*=train]').val(),
            });             
           })
        }else{
          self.store.deletePlace({
            place: passenger.find('[name*=place]').val(),
            wagon: passenger.find('[name*=wagon]').val(),
            train: passenger.find('[name*=train]').val(),
          });
        }
        passenger.remove();
        if(self.form.find('.js-user-data').length == 0){
          scenario.isTransfer() ? render.breadcrumbs.steps.first().click() : render.backToChoosePlaces(event);
        }else{
          self.reidentPasengers();
          self.hidePasengerWarn();
        }
      }
    });
  });
  
  this.services.on('change', function(event){
    var priceBlock  = $('.js-total-amount');
    var currency    = priceBlock.data('currency');
    var total       = 0;

    $.each(self.store.places, function(index, storedPlace){
      var pass = $('.js-user-data[data-wagon='+storedPlace.wagon+'][data-place='+storedPlace.place+']');
      var services = pass.find('.js-service-data:checked');
      var ticketCost = storedPlace.costWithoutService;

      $.each(services, function(i, service){
        ticketCost += parseFloat($(service).data('cost'));
      });

      total += self.priceWithCommission(ticketCost);
    });

    priceBlock.html(self.priceToString(total, currency));
  });


  $(document).on('click', function(event){
    // self.form.find('.age-picker').addClass('hidden').hide();
    var childBlock = $(event.target).not('span').parents('li').find('[name*=document_type][value=child]').parents('li');
    if(childBlock){
      childBlock.find('.age-picker').removeClass('hidden').show();
    }
  });

  this.form.find('[name*=age]').on('click', function(event){
    $(event.target).siblings('.age-picker').removeClass('hidden').show();
  });

  // this.form.find('[name*=document_type]').on('click', function(event){
  //   // event.stopPropagation();
  //   var documentType = $(event.target);
  //   switch(documentType.val()) {
  //   case 'child':
  //     var agePickerBlock = documentType.siblings('.age-picker-block').find('.age-picker');
  //     if (!!documentType.find('[name*=age]').val()){
  //       agePickerBlock.addClass('hidden').hide();
  //     }else{
  //       agePickerBlock.removeClass('hidden').show();
  //     }
  //     break;
  //   case 'full':
  //     break;
  //   case 'student':
  //     $(event.target).siblings('.input-block').find('[name*=passport]').focus();
  //     break;
  //   }
  // });

  this.form.find('[name*=document_type]').on('change', function(event){
    // event.stopPropagation();
    var documentType = $(event.target);
    var passengerBlock = documentType.parents('.js-user-data')
    
    switch(documentType.val()) {
    case 'child':
      passengerBlock.find('.age-block').removeClass('d-none')
      passengerBlock.find('.student-block').addClass('d-none')
      break;
    case 'full':
      passengerBlock.find('.age-block,.student-block').addClass('d-none')
      break;
    case 'student':
      passengerBlock.find('.age-block').addClass('d-none')
      passengerBlock.find('.student-block').removeClass('d-none')
      break;
    }
  });

  $.each(this.form.find('.age-picker-block'), function(index, agePickerBlock){
    new AgePicker(agePickerBlock);
  });

  initTooltip();
};
PassengersFrom.prototype.priceToString = function(cost, currency){
  return cost.toFixed(2) + ' ' + currency;
};
PassengersFrom.prototype.priceWithCommission = function(amount){
  if(amount >= percet_commission_start_from_amount){
    var commission = (amount * percent_commission / 100);
  }else{
    var commission = fixed_commission;
  }
  return amount + commission;
};
PassengersFrom.prototype.onStoreChange = function(){
  var total = 0;
  $.each(this.store.places, function(index, storedPlace){
    total += storedPlace.cost.amount;
  });
  this.totalPrice.text(render.total_price(total));
};

PassengersFrom.prototype.reidentPasengers = function(){
  $.each($('.js-user-data').not('.js-cargo-item'), function(index, passenger){
    $(passenger).find('.js-document-index').text(index+1);
  });
  $.each($('.js-user-data.js-cargo-item'), function(index, passenger){
    $(passenger).find('.js-cargo-index').text(index+1);
  });  
};
PassengersFrom.prototype.hidePasengerWarn = function(){
  if( $('.js-user-data').not('.js-cargo-item').length == 0 ){ 
    
    $('.js-passenger-data-warn').hide();
  }
};

PassengersFrom.prototype.validateCargoItem = function(passenger, passenger_index){
  var self = this;
  var result = true
  $.each(passenger.find('input'), function(index, field){
    var simple_input     = new Validation('default', $(field));
    if(!simple_input.isValid()){
      simple_input.showError();
      result = false;
    }
  });
  return result;
};

PassengersFrom.prototype.valid = function(){
  var self      = this;
  var indicator = true;
  var results = $.map(this.form.find('.js-user-data'), function(passenger, index){
    if(!indicator) return false;
    
    var $passenger = $(passenger);
    if($passenger.hasClass('js-cargo-item')) return self.validateCargoItem($passenger, index);

    var lastname     = new Validation('lastname', $passenger.find('.js-document-lastname'));
    if (!lastname.isValid($passenger.find('.js-document-firstname').val() )){
      lastname.showError();
      return indicator = false;
    }

    var firstname = new Validation('firstname', $passenger.find('.js-document-firstname'));
    if (!firstname.isValid($passenger.find('.js-document-lastname').val() )){
      firstname.showError();
      return indicator = false;
    }

    var docType = $passenger.find('[name*=document_type]:checked');
    if(docType.val() === 'full'){

    }else if(docType.val() === 'child'){
      var age = new Validation('age', $passenger.find('.js-document-age'));
      if(!age.isValid()){
        age.showError();
        return indicator = false;
      }
    }else if(docType.val() === 'student'){
      var passport   = new Validation('passport', $passenger.find('.js-document-passport'));
      if (!passport.isValid()){
        passport.showError();
        return indicator = false;
      }
    }

    return true;
  });

  return results.every(function(element){return element});
};

export default PassengersFrom